.clip-text {
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

#heading {
  background: linear-gradient(180deg, #f282f5 0%, #903795 100%);
}

#getStarted {
  background: linear-gradient(91deg, #f57d34 0%, #c44eb9 100%);
}

#AI,
#whatYouWant,
#learnForYou {
  background: linear-gradient(91deg, #f57d34 0%, #c44eb9 100%);
  font-family: Satoshi;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 180%;
  letter-spacing: 0.4px;
}

#life {
  color: var(--Caarya-Red-500, #ed4c41);
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.32px;
}
#ME {
  color: var(--Accent-Primary-700, #1a6be5);
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.32px;
}
#insightful {
  background: linear-gradient(91deg, #f57d34 0%, #c44eb9 100%);
}
#expertise,
#memory {
  background: linear-gradient(90deg, #f57d34 0%, #c44eb9 70.83%);
}

.gradient {
  background: linear-gradient(159deg, #75dd67 3.93%, #33d6e1 103.93%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
