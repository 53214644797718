@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  background-attachment: fixed !important;
  background-position: 100%;
  min-height: 100vh; }

@media screen and (min-width: 768px) {
  body {
    background: #fafafa; } }

@media screen and (max-width: 767px) {
  body {
    background: #fafafa; } }

@media screen and (max-width: 767px) {
  .home,
  .agent {
    background-color: #fafafa; } }

@media screen and (min-width: 768px) {
  .home,
  .agent {
    background-color: #fafafa; } }

@media screen and (max-width: 767px) {
  .newVibeModal {
    background: radial-gradient(169.17% 100% at 50% 100%, rgba(15, 15, 15, 0) 0%, #0f0f0f 70%), url("assets/images/bg.png") lightgray 50%/cover no-repeat;
    background-attachment: fixed !important; } }

@media screen and (min-width: 768px) {
  .newVibeModal {
    background: radial-gradient(169.17% 100% at 50% 100%, rgba(15, 15, 15, 0) 0%, #0f0f0f 70%), url("../../images/bg_web.png") lightgray 50%/cover no-repeat;
    background-attachment: fixed !important; } }

.avatar {
  background: url("../../images/robot.png") lightgray -5.006px/174.654% 174.654% no-repeat;
  background-position: center -3px;
  background-color: rgba(255, 255, 255, 0.4);
  background-blend-mode: normal; }

.avatar-dark {
  background: url("../../images/robot.png") lightgray -5.006px/174.654% 174.654% no-repeat;
  background-position: center -3px;
  background-color: #0f0f0f;
  background-blend-mode: normal; }

.hide-scrollbar {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */ }

.hide-scrollbar::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */ }

.animate-bounce-custom {
  animation: bounce 2s infinite; }

@keyframes bounce {
  0%,
  100% {
    transform: translateY(-5%);
    animation-timing-function: linear; }
  50% {
    transform: translateY(0);
    animation-timing-function: linear; } }

.category-heading {
  background: linear-gradient(92deg, #e0447c 0%, #6775f5 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

@keyframes float {
  0% {
    transform: translateY(0px); }
  50% {
    transform: translateY(-20px); }
  100% {
    transform: translateY(0px); } }

.yoda-animation {
  transform: translateY(0px);
  animation: float 6s ease-in-out infinite; }

/*Cube*/
.animated-cube {
  animation: hue-rotate 3s linear infinite; }

@keyframes hue-rotate {
  from {
    -webkit-filter: hue-rotate(0);
    -moz-filter: hue-rotate(0);
    -ms-filter: hue-rotate(0);
    filter: hue-rotate(0); }
  to {
    -webkit-filter: hue-rotate(360deg);
    -moz-filter: hue-rotate(360deg);
    -ms-filter: hue-rotate(360deg);
    filter: hue-rotate(360deg); } }

/* Logo*/
.otp-login-container .title-font {
  font-family: Poppins;
  font-weight: 700;
  color: #f2c0f2; }

.otp-login-container .caption-font {
  font-family: inter;
  font-size: 2em;
  font-weight: 700;
  color: white; }

.otp-login-container form {
  padding: 25px;
  margin-right: auto;
  margin-left: auto;
  max-width: 23.75rem;
  background-color: #fbe1d1;
  border-radius: 3px;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  font-family: inter; }

.otp-login-container input,
.otp-login-container textarea {
  display: block;
  padding: 0.5rem 0.75rem;
  width: 100%;
  font-size: 1rem;
  line-height: 1.25;
  color: #55595c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #eee;
  border-left: 0;
  border-radius: 3px;
  transition: all 0.25s cubic-bezier(0.4, 0, 1, 1); }

.otp-login-container input:focus,
.otp-login-container textarea:focus {
  outline: 0;
  border-bottom-color: #f2c0f2; }

.otp-login-container textarea {
  resize: vertical; }

.otp-login-container .btn {
  display: inline-block;
  padding: 0.75rem 1rem;
  vertical-align: middle;
  white-space: nowrap;
  border: 1px solid transparent;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 0.2s cubic-bezier(0.4, 0, 1, 1);
  min-width: 160px; }

.otp-login-container .btn:focus,
.otp-login-container .btn:hover {
  /* box-shadow: 0 18px 35px rgba(50, 50, 93, 0.1), 0 8px 15px rgba(0, 0, 0, 0.07); */
  background-color: #2c1304 !important;
  color: #f9ccb2 !important; }

.otp-login-container .btn:focus {
  outline: 0; }

.otp-login-container body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.otp-login-container .poppins {
  font-family: "Poppins", sans-serif; }

.otp-login-container .inter {
  font-family: "Inter", sans-serif; }

.purple-bg {
  background: linear-gradient(252.44deg, #4e1882 0%, #300964 100%), #9929ea;
  background-size: cover; }

.login-bg {
  background-color: #fff; }

.otp-login-container .font-purple {
  color: #4e1882; }

.otp-login-container .light-purple-bg {
  background-color: #f2c0f2; }

.border-chronos {
  border-color: rgba(221, 214, 254, 0.15) !important; }

/* Form */
.glass-form {
  background: rgba(254, 214, 210, 0.2) !important;
  box-shadow: 0 8px 32px 0 rgba(254, 214, 210, 0.35) !important;
  backdrop-filter: blur(5px) !important;
  -webkit-backdrop-filter: blur(5px) !important;
  -moz-backdrop-filter: blur(5px) !important;
  border-radius: 10px !important;
  border: 1px solid rgba(254, 214, 210, 0.15) !important; }

.glass-modal-header {
  background: rgba(254, 214, 210, 0.25) !important;
  backdrop-filter: blur(5px) !important;
  -webkit-backdrop-filter: blur(5px) !important;
  -moz-backdrop-filter: blur(5px) !important; }

.waves {
  position: relative;
  width: 100vw;
  margin-bottom: -7px;
  /*Fix for safari gap*/
  height: 80px;
  transform: rotate(180deg); }

/* Animation */
.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite; }

.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s; }

.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s; }

.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s; }

.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s; }

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0); }
  100% {
    transform: translate3d(85px, 0, 0); } }

/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 50px; }
  .content {
    height: 30vh; }
  h1 {
    font-size: 24px; } }

.header {
  position: relative;
  text-align: center;
  background: linear-gradient(180deg, #1e79c3 9.68%, #0497ae 47.47%, #33d6e1 68.98%, #75dd67 91.99%, #75dd67 103.22%);
  color: white;
  opacity: 0.6; }

@media (min-height: 650px) {
  .submit-wrapper {
    height: 153px; } }

@media (max-height: 650px) {
  .submit-wrapper {
    height: 100px; } }

@media (max-height: 560px) {
  .submit-wrapper {
    height: 60px; } }

@media (min-height: 800px) and (max-width: 767px) {
  .career,
  .personal,
  .college {
    height: calc(100vh - 200px); } }

@media (max-height: 799px) and (max-width: 767px) {
  .career,
  .personal,
  .college {
    min-height: calc(100vh - 200px);
    overflow-y: auto; } }

@media screen and (max-width: 400px) {
  .nextButton {
    max-width: 302px; } }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0; }

.clip-text {
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important; }

#heading {
  background: linear-gradient(180deg, #f282f5 0%, #903795 100%); }

#getStarted {
  background: linear-gradient(91deg, #f57d34 0%, #c44eb9 100%); }

#AI,
#whatYouWant,
#learnForYou {
  background: linear-gradient(91deg, #f57d34 0%, #c44eb9 100%);
  font-family: Satoshi;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 180%;
  letter-spacing: 0.4px; }

#life {
  color: var(--Caarya-Red-500, #ed4c41);
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.32px; }

#ME {
  color: var(--Accent-Primary-700, #1a6be5);
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.32px; }

#insightful {
  background: linear-gradient(91deg, #f57d34 0%, #c44eb9 100%); }

#expertise,
#memory {
  background: linear-gradient(90deg, #f57d34 0%, #c44eb9 70.83%); }

/*Responsiveness*/
/*Responsiveness*/
/* Main Theme Colors*/
.font-indigo-500 {
  color: #5426c6; }

.bg-indigo-500 {
  background-color: #5426c6; }

.border-indigo-500 {
  border-color: #5426c6; }

.font-pink-50 {
  color: #fff9ec; }

.font-pink-100 {
  color: #fef6f1; }

.font-pink-300 {
  color: #f4a374; }

.font-pink-500 {
  color: #ce5511; }

.bg-pink-50 {
  background-color: #fff9ec; }

.bg-pink-100 {
  background-color: #fef6f1; }

.bg-pink-300 {
  background-color: #f4a374; }

.bg-pink-500 {
  background-color: #ce5511; }

.border-pink-50 {
  border-color: #fff9ec; }

.border-pink-100 {
  border-color: #fef6f1; }

.border-pink-300 {
  border-color: #f4a374; }

.border-pink-500 {
  border-color: #ce5511; }

.font-gray-50 {
  color: #f8f8f9; }

.font-gray-60 {
  color: #f1f1f1; }

.font-gray-100 {
  color: #f4f4f4; }

.font-gray-150 {
  color: #e1e1e1; }

.font-gray-200 {
  color: #d8d8d8; }

.font-gray-250 {
  color: #a4a2a5; }

.font-gray-300 {
  color: #6d6d6d; }

.font-gray-350 {
  color: #747474; }

.font-gray-400 {
  color: #424242; }

.font-gray-500 {
  color: #332e35; }

.font-gray-600 {
  color: #323232; }

.font-gray-650 {
  color: #373739; }

.font-gray-900 {
  color: #2b2b2d; }

.font-gray-1000 {
  color: #171718; }

.bg-gray-50 {
  background-color: #f8f8f9; }

.bg-gray-60 {
  background-color: #f1f1f1; }

.bg-gray-100 {
  background-color: #f4f4f4; }

.bg-gray-150 {
  background-color: #e1e1e1; }

.bg-gray-200 {
  background-color: #d8d8d8; }

.bg-gray-250 {
  background-color: #a4a2a5; }

.bg-gray-300 {
  background-color: #6d6d6d; }

.bg-gray-350 {
  background-color: #747474; }

.bg-gray-400 {
  background-color: #424242; }

.bg-gray-500 {
  background-color: #332e35; }

.bg-gray-600 {
  background-color: #323232; }

.bg-gray-650 {
  background-color: #373739; }

.bg-gray-900 {
  background-color: #2b2b2d; }

.bg-gray-1000 {
  background-color: #171718; }

.border-gray-50 {
  border-color: #f8f8f9; }

.border-gray-60 {
  border-color: #f1f1f1; }

.border-gray-100 {
  border-color: #f4f4f4; }

.border-gray-150 {
  border-color: #e1e1e1; }

.border-gray-200 {
  border-color: #d8d8d8; }

.border-gray-250 {
  border-color: #a4a2a5; }

.border-gray-300 {
  border-color: #6d6d6d; }

.border-gray-350 {
  border-color: #747474; }

.border-gray-400 {
  border-color: #424242; }

.border-gray-500 {
  border-color: #332e35; }

.border-gray-600 {
  border-color: #323232; }

.border-gray-650 {
  border-color: #373739; }

.border-gray-900 {
  border-color: #2b2b2d; }

.border-gray-1000 {
  border-color: #171718; }

.text-logo {
  background: #ce5511;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent; }

.primary-cta {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #fef6f1;
  background: #ce5511;
  border-radius: 30px; }
  .primary-cta:disabled {
    color: #e1e1e1; }
  .primary-cta:hover {
    background-color: #ce5511; }
  .primary-cta:active {
    background-color: #ce5511; }
  .primary-cta:disabled {
    background: #f4f4f4; }

.secondary-cta {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #ce5511;
  background: "white";
  border-radius: 30px;
  border: 1px solid #ce5511; }
  .secondary-cta:disabled {
    color: #e1e1e1; }
  .secondary-cta:hover {
    background-color: #f4a374; }
  .secondary-cta:active {
    background-color: #f4a374; }
  .secondary-cta:disabled {
    background: #f4f4f4; }

.tertiary-cta {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #5426c6;
  background: "white";
  border-radius: 30px;
  border: 1px solid #5426c6; }
  .tertiary-cta:disabled {
    color: #e1e1e1; }
  .tertiary-cta:hover {
    background: "white"; }
  .tertiary-cta:active {
    background: "white"; }
  .tertiary-cta:disabled {
    background: #f4f4f4; }

.login-cta {
  border-radius: 16px;
  background: linear-gradient(180deg, #ed4c41 0%, #f57d34 100%); }

.btn-red-gradient {
  background: linear-gradient(95deg, #fb6f60 0%, #ed4c41 100%);
  box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 0px 16px 0px rgba(0, 0, 0, 0.05); }

.input-label {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: black; }
  @media (min-width: 1024px) {
    .input-label {
      font-size: 14px; } }

input:focus,
textarea:focus,
button:focus {
  outline-style: none; }

.theme-input {
  background: #f1f4f8;
  border-radius: 16px;
  padding: 10px 20px; }
  .theme-input:hover {
    cursor: pointer; }
  .theme-input:active, .theme-input:focus {
    border: 1px solid #747474; }

.text-input {
  background-color: transparent;
  font-family: "Lato", sans-serif;
  width: 100%;
  padding: 12px 24px;
  color: #363430;
  font-weight: 400;
  border-bottom: 1px solid #cfcdc9; }
  .text-input::placeholder {
    font-weight: 400;
    color: #e7e6e5; }
  .text-input:active, .text-input:focus {
    border-bottom: 1px solid #363430;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    outline: none; }
  .text-input.error {
    border-bottom: 1px solid #ed4c41; }

.modals-component {
  background: #f8f8f9;
  mix-blend-mode: normal;
  box-shadow: 0px -10px 20px -13px rgba(0, 0, 0, 0.15);
  border-radius: 20px 20px 0px 0px; }

.input-field-box {
  background: #ffffff;
  mix-blend-mode: normal;
  border-radius: 10px; }

.input-field-box-selected {
  background: #fcdccc;
  mix-blend-mode: normal;
  border-radius: 10px; }

.shadow-container {
  box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.1); }

.evalucon-nav {
  border: 1px solid rgba(99, 99, 99, 0.4);
  background: linear-gradient(117deg, rgba(144, 144, 144, 0.2) 33.67%, rgba(107, 105, 105, 0.05) 68.01%);
  backdrop-filter: blur(20px); }

.slick-next:before {
  content: "" !important; }

.slick-prev:before {
  content: "" !important; }

.red-card {
  border-radius: 56px;
  border: 1px solid var(--caarya-red-300, #ff7e6e);
  background: rgba(255, 233, 229, 0.3);
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.1), 0px 0px 16px 0px rgba(0, 0, 0, 0.05); }

.yellow-card {
  border-radius: 56px;
  border: 1px solid var(--fusion-500, #ffbc00);
  background: rgba(253, 247, 233, 0.3);
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.1), 0px 0px 16px 0px rgba(0, 0, 0, 0.05); }
